<template>
  <div
    class="product-item w-full cursor-pointer p-0"
    @click="goToItem(product)"
  >
    <section :class="itemView">
      <b-card class="ecommerce-card" no-body>
        <div class="item-img text-center">
          <b-img
            :alt="`${product.name}-${product.id}`"
            fluid
            class="card-img-top"
            :src="product.primary_image"
            @error="(e) => appUtils.onHandleErrorLoadImage(e)"
          />
        </div>

        <!-- Product Details -->
        <b-card-body>
          <div class="item-wrapper">
            <div>
              <div class="item-rating">
                <ul class="unstyled-list list-inline mb-0">
                  <li
                    v-for="star in 5"
                    :key="star"
                    class="ratings-list-item"
                    :class="{ 'ml-25': star - 1 }"
                  >
                    <feather-icon
                      icon="StarIcon"
                      size="16"
                      :class="[
                        { 'fill-current': star <= 5 },
                        star <= 5 ? 'text-warning' : 'text-muted',
                      ]"
                    />
                  </li>
                </ul>
              </div>
              <h6 class="item-price">
                {{
                  defaultVariant
                    ? `${appUtils.numberFormat(defaultVariant.price)}đ/
                ${defaultVariant.ecom_unit.name}`
                    : product.variants && product.variants.length
                    ? `${appUtils.numberFormat(product.variants[0].price)}đ/
                ${product.variants[0].ecom_unit.name}`
                    : 0
                }}
              </h6>
            </div>
          </div>
          <h6 class="item-name">
            <b-link
              class="text-body"
              :to="{
                name: 'ProductDetail',
                params: { slug: product.slug },
              }"
              >{{ product.name }}</b-link
            >
          </h6>
          <b-card-text class="item-description">
            {{ product.short_desc }}
          </b-card-text>
        </b-card-body>

        <!-- Product Actions -->
        <div class="item-options text-center px-2 pb-1">
          <b-button
            variant="primary"
            tag="a"
            class="btn-cart flex justify-center"
          >
            <!-- <feather-icon icon="ShoppingCartIcon" class="mr-50" /> -->
            <span>Xem chi tiết</span>
          </b-button>
        </div>
      </b-card>
    </section>
  </div>
</template>

<script>
// import router from '@/router'
import appUtils from "../../utils/appUtils";
import { PRODUCT_TYPE } from "../../utils/constant";
import { useShopUi } from "../../views/pages/e-commerce/e-commerce-shop/useECommerceShop";

export default {
  name: "ProductItem",
  props: {
    product: Object,
  },
  data() {
    return {
      appUtils,
      PRODUCT_TYPE,
    };
  },
  computed: {
    defaultVariant() {
      return this.product?.variants.find((elm) => elm.default === 2);
    },
  },
  methods: {
    goToItem(product) {
      this.$router
        .push({ path: `/marketplace/${product.slug}` })
        .catch(() => {});
    },
    handleClickBuyProduct(product) {
      // if (product.is_require_form === 1) {
      //   this.$emit('clickService', product)
      // } else if (
      //   product.type === PRODUCT_TYPE.SERVICE &&
      //   (!product.is_require_form || product.is_require_form === 2)
      // ) {
      //   appUtils.addToCart(product)

      //   if (!this.$user) {
      //     this.$targetRoute = '/#/shopping-cart'
      //     this.$router.push({ name: 'Login' }).catch((_) => {})
      //     return false
      //   }

      //   this.$router
      //     .push({
      //       name: 'OrderConfirm'
      //     })
      //     .catch((_) => {})
      // } else {
      //   appUtils.addToCart(product)
      // }

      this.$router
        .push({
          path: `/marketplace/${product.slug}`,
        })
        .catch(() => {});
    },
  },
  setup() {
    const { itemView, itemViewOptions, totalProducts } = useShopUi();
    return {
      itemView,
      appUtils,
    };
  },
};
</script>

<style lang="scss" scoped>
.product-item {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  width: 100%;
  padding: 0 16px 16px 16px;
  border-radius: 4px;
  transition: all 0.3s ease;
  &:hover {
    box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.15);
    transform: translateY(-10px);
  }
}

.item-price {
  font-size: 18px;
  line-height: 28px;
  color: #20419b;
  font-weight: 600;
  margin-top: 8px;
  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }
}
.item-img {
  padding: 16px 16px 0;
  img {
    height: 250px;
    object-fit: cover;
  }
}

.item-name {
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
  .text-body {
    color: #000 !important;
  }
  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }
}
.item-description {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
  min-height: 42px;
}
</style>
