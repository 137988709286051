<template>
  <div v-loading="isLoading" class="ec-container">
    <div class="ec-container">
      <!-- <BreadcrumbCpn
        :breadcrumb="breadcrumb"
        class="mb-2"
        v-if="filters.keyword || filters.cate_id || isViewMore || filters.brand_id || filters.manufacturer_id || filters.origin_id"
      />-->

      <div class="flex flex-column flex-lg-row items-center gap-4 justify-between">
        <div style="width: 100%">
          <span class>Thương hiệu</span>
          <b-form-select
            v-model="filters.brand_id"
            :options="listBrands"
            @change="() => fetchProducts()"
          ></b-form-select>
        </div>
        <div style="width: 100%">
          <span class>Nhà sản xuất</span>
          <b-form-select
            v-model="filters.manufacturer_id"
            :options="listManufacturers"
            @change="() => fetchProducts()"
          ></b-form-select>
        </div>
        <div style="width: 100%">
          <span class>Nguồn gốc</span>
          <b-form-select
            v-model="filters.origin_id"
            :options="listOrigins"
            @change="() => fetchProducts()"
          ></b-form-select>
        </div>
        <div class="flex items-center gap-4 w-full justify-end filter-order">
          <b-card-text class="mb-0 text-black">Sắp xếp theo:</b-card-text>
          <div class="flex items-center gap-4">
            <b-form-radio v-model="filters.order" name="Giá cao" value="desc">Giá cao</b-form-radio>
            <b-form-radio v-model="filters.order" name="Giá thấp" value="asc">Giá thấp</b-form-radio>
          </div>
        </div>
      </div>
      <div v-if="filters.keyword" class="w-full mt-2" style="font-size: 20px">
        <p>
          Hiển thị {{ products.length }} kết quả cho từ khóa
          <b>"{{ filters.keyword }}"</b>
        </p>
      </div>
      <div class="ec-body mt-2">
        <div
          class="ec-body-list row mb-5"
          v-if="
            filters.keyword ||
            filters.cate_id ||
            isViewMore ||
            filters.brand_id ||
            filters.manufacturer_id ||
            filters.origin_id
          "
        >
          <div
            v-for="(item, i) in products"
            :key="'product-' + item.id + i"
            class="col-md-4 col-xl-3"
          >
            <ProductItem :product="item" />
          </div>
          <div
            v-if="products.length == 0"
            class="flex justify-center flex-col items-center w-full mt-2"
          >
            <img src="/images/Page/empty.png" alt class="w-40 h-40" />
            <p class="mt-1">Không có sản phẩm nào.</p>
          </div>
        </div>
        <div class="pb-5" v-else>
          <!-- <p class="text-base font-medium">Sản phẩm khuyến mãi</p>
          <div class="ec-body-list row mb-5">
            <div
              v-for="p in products.slice(0, 5)"
              :key="'product-' + p.id"
              class="col-md-4 col-xl-3 mb-3"
            >
              <ProductItem :product="p" />
            </div>
          </div>-->
          <div v-if="products.length != 0">
            <div class="flex items-center justify-between">
              <p
                class="text-base font-medium"
                style="font-size: 16px; line-height: 24px"
              >Tất cả sản phẩm</p>
            </div>
            <div class="ec-body-list row">
              <div v-for="p in products" :key="'product-' + p.id" class="col-md-4 col-xl-3">
                <ProductItem :product="p" />
              </div>
            </div>
            <div class="flex justify-center">
              <b-button
                v-if="filters.page_num < totalPages"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary mx-auto"
                size="lg"
                @click="handleViewMoreProducts"
              >Xem thêm</b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <ModalConfirmRequirePaper
      ref="modalConfirmRequire"
      @confirm="confirmRequiredPaper"
    />-->
  </div>
</template>

<script>
import ProductItem from '../../../../components/marketplace/ProductItem.vue'
import store from '@/store'
import appUtils from '../../../../utils/appUtils'
import { PRODUCT_TYPE } from '@/utils/constant'
import BreadcrumbCpn from '../../../../components/Layout/BreadcrumbCpn.vue'
import EventBus from '@/utils/EventBus'

export default {
  name: 'Marketplace',
  components: {
    ProductItem,
    BreadcrumbCpn
  },
  data: function() {
    return {
      isViewMore: false,
      isLoading: true,
      appUtils,
      products: [],
      categories: [],
      filters: {
        keyword: '',
        page_num: 1,
        page_size: 12,
        order: 'desc',
        sort_by: 'price',
        tags: '',
        cate_id: '',
        status: '',
        brand_id: '',
        manufacturer_id: '',
        origin_id: ''
      },
      totalPages: 0,
      activeCategoryIndex: -1,
      activeCategoryId: -1,
      PRODUCT_TYPE,
      listBrands: [],
      listManufacturers: [],
      listOrigins: []
    }
  },
  computed: {
    breadcrumb() {
      return [
        {
          text: this.$t('order_confirm.lbl_back'),
          to: {
            path: '/marketplace?type=0'
          }
        }
      ]
    }
  },
  async created() {
    const query = this.$route.query

    if (query?.search) {
      this.setSearchInput(query?.search)
    }

    // await this.fetchCategories()
    // await this.getProductsByCategoryWithRoute();
    this.handleChangeCategory({ id: Number(this.$route.query?.type) || null })
    this.user = appUtils.getLocalUser()
    EventBus.$on('showSearchResults', this.setSearchInput)
  },
  destroyed() {
    EventBus.$off('showSearchResults', this.setSearchInput)
  },
  watch: {
    $route: {
      handler(data) {
        // if (data.query?.type) {
        // await this.getProductsByCategoryWithRoute();
        // } else {
        // await this.fetchProducts();
        // }
        this.filters.keyword = data.query?.search
        this.handleChangeCategory({ id: Number(data.query?.type) || null })
        this.isViewMore = false
      },
      deep: true
    },
    'filters.order': {
      handler() {
        this.fetchProducts()
      }
    }
  },
  methods: {
    async getProductsByCategoryWithRoute() {
      const query = this.$route.query

      if (!this.categories?.length) {
        // await this.fetchCategories()
      }

      const cates = this.categories?.map(cate =>
        appUtils.slugifyStr(cate?.name)
      )

      if (query?.type) {
        const cateIndex = cates?.findIndex(item => item === query?.type)

        if (cateIndex === -1 || typeof cateIndex === 'undefined') {
          this.getProductsByCategoryId(-1, -1)
        } else {
          this.getProductsByCategoryId(
            this.categories[cateIndex]?.id,
            cateIndex
          )
        }
      } else {
        this.getProductsByCategoryId(-1, -1)
      }
    },
    confirmRequiredPaper(payload) {
      if (payload.product.type === PRODUCT_TYPE.PRODUCT) {
        appUtils.addToCart(payload.product)
      } else {
        appUtils.addToCart(payload.product)

        if (!this.$user) {
          this.$targetRoute = '/#/shopping-cart'
          this.$router.push({ name: 'Login' })
          return false
        }

        try {
          this.$router
            .push({
              name: 'OrderConfirm'
            })
            .catch(_ => {})
        } catch (error) {
          console.log(error)
        }
      }
    },
    async setSearchInput(value) {
      this.$router.replace({ query: { search: value } }).catch(() => {})
      this.filters.keyword = value
      await this.fetchProducts()
    },
    async fetchProducts(isViewMore = false) {
      this.isLoading = true
      let params = {}
      if (isViewMore) this.filters.page_num += 1
      if (this.filters.cate_id) {
        params = {
          keyword: this.filters.keyword,
          order: this.filters.order,
          page_num: this.filters.page_num,
          sort_by: this.filters.sort_by,
          status: this.filters.status,
          tags: this.filters.tags,
          cate_id: this.filters.cate_id,
          page_size: 12,
          brand_id: this.filters.brand_id,
          manufacturer_id: this.filters.manufacturer_id,
          origin_id: this.filters.origin_id,
          supplier_id: JSON.parse(localStorage.user).supplierInfo.id
        }
      } else {
        params = {
          ...this.filters,
          supplier_id: JSON.parse(localStorage.user).supplierInfo.id
        }
      }
      await store
        .dispatch('app-ecommerce/fetchProducts', params)
        .then(res => {
          this.products = isViewMore
            ? [...this.products, ...res.data.data]
            : res.data.data
          this.totalPages = res.data.page?.total_page
          this.isLoading = false
        })
        .catch(e => {
          this.isLoading = false
          console.log(e)
        })
    },
    handleViewMoreProducts() {
      // this.isViewMore = true
      this.fetchProducts(true)
    },
    async fetchBrands() {
      await store
        .dispatch('app-ecommerce/fetchBrands', {
          page_size: 100,
          page_num: 1,
          supplier_id: JSON.parse(localStorage.user).supplierInfo.id
        })
        .then(res => {
          this.isLoading = false
          this.listBrands = res.data.data
          this.listBrands = this.listBrands?.map(elm => {
            return {
              text: elm.name,
              value: elm.id
            }
          })
          this.listBrands.unshift({
            value: '',
            text: 'Chọn thương hiệu'
          })
        })
        .catch(e => {
          this.isLoading = false
          console.log(e)
        })
    },
    async fetchManufacturers() {
      await store
        .dispatch('app-ecommerce/fetchManufacturers', {
          page_size: 100,
          page_num: 1,
          supplier_id: JSON.parse(localStorage.user).supplierInfo.id
        })
        .then(res => {
          this.isLoading = false
          this.listManufacturers = res.data.data
          this.listManufacturers = this.listManufacturers?.map(elm => {
            return {
              text: elm.name,
              value: elm.id
            }
          })
          this.listManufacturers.unshift({
            value: '',
            text: 'Chọn nhà sản xuất'
          })
        })
        .catch(e => {
          this.isLoading = false
          console.log(e)
        })
    },
    async fetchOrigins() {
      await store
        .dispatch('app-ecommerce/fetchOrigins', {
          page_size: 100,
          page_num: 1,
          supplier_id: JSON.parse(localStorage.user).supplierInfo.id
        })
        .then(res => {
          this.isLoading = false
          this.listOrigins = res.data.data
          this.listOrigins = this.listOrigins?.map(elm => {
            return {
              text: elm.name,
              value: elm.id
            }
          })
          this.listOrigins.unshift({
            value: '',
            text: 'Chọn nguồn gốc'
          })
        })
        .catch(e => {
          this.isLoading = false
          console.log(e)
        })
    },

    getProductsByCategoryId(categoryId, index, isHandleCurrentTab) {
      if (!categoryId) return

      const cateData = this.categories[index]

      this.activeCategoryIndex = index
      this.activeCategoryId = categoryId

      if (categoryId === -1) {
        this.$router
          .push({
            query: {
              ...this.$route.query
              //   type: ''
            }
          })
          .catch(_ => {})
      } else {
        this.$router
          .push({
            query: {
              ...this.$route.query,
              type: appUtils.slugifyStr(cateData?.name)
            }
          })
          .catch(_ => {})
      }

      if (isHandleCurrentTab) return

      if (categoryId === -1) {
        this.fetchProducts()
        return
      }
      this.fetchProducts()
    },
    handleChangeCategory(item) {
      if (this.filters.cate_id == item.id) return
      this.filters.cate_id = item?.id
      this.filters.page_num = 1
      this.fetchProducts()
    }
  },
  mounted() {
    this.fetchBrands()
    this.fetchManufacturers()
    this.fetchOrigins()
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .ec-container {
    background: rgba(247, 247, 249, 1);
    padding-top: 32px;
  }
  .ec-header {
    display: flex;
  }
  .search-wraper {
    width: calc(100% - 181px);
  }
  .cart-wraper {
    width: 173px;
  }
  .el-input__inner {
    border: 2px solid rgba(213, 210, 249, 1);
    height: 64px;
    border-radius: 67px;
  }
  .el-input--suffix .el-input__inner {
    padding-right: 45px;
  }
  .el-input__suffix {
    right: 18px;
  }
  .view-more {
    padding: 4px;
    background-color: #20419b;
    font-size: 22px;
    display: block;

    &:hover {
      text-decoration: underline;
      color: #20419b;
    }
  }
  .button-shopping {
    height: 50px;
    width: 125px;
    border-radius: 9px;
    padding: 6px 12px 6px 12px;
  }
}
.filter {
  justify-content: space-between;

  .filter-search {
    align-items: center;
  }
  .filter-order {
    justify-content: end;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
    .filter-search {
      justify-content: center;
    }
    .filter-order {
      justify-content: flex-start;
    }
  }
}
.navbar-container {
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  border-radius: 6px;
  margin-bottom: 40px;
  .navbar-item {
    // color: #000;
    padding: 8px 12px;

    &:hover {
      text-decoration: underline;
    }
  }
  .navbar-item-active {
    background: linear-gradient(118deg, #20419b, rgba(32, 65, 155, 0.7));
    box-shadow: 0px 0px 6px 1px rgba(32, 65, 155, 0.6);
    border-radius: 4px;
    color: #fff;
    &:hover {
      text-decoration: none;
    }
  }
  @media screen and (max-width: 768px) {
    white-space: nowrap;
    overflow: auto;
  }
}
</style>
